<template>
  <!--begin::Customer Listing-->
  <div class="warranty-template">
    <!-- {{ dataLoading }} -->
    <PageHeaderCount
      moduleType="preventive-maintanance"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_warranty"
      countkey="preventive_status_count"
    ></PageHeaderCount>
    <!--  <v-col
        v-if="alphabets.length"
        md="12"
        class="alphabets-filter d-flex justify-center"
      >
        <div class="alphabets-scrollable" v-if="false">
          <v-btn
            depressed
            v-on:click="getLineItem(null, 'alphabet')"
            :class="{
              'cyan--text': null == alphabet,
              'cyan white--text': null != alphabet,
            }"
            class="custom-bold-button"
            small
            >All</v-btn
          >
          <v-btn
            depressed
            v-for="(row, index) in alphabets"
            v-on:click="getLineItem(row, 'alphabet')"
            :class="{
              'cyan--text': row == alphabet,
              'cyan white--text': row != alphabet,
            }"
            class="custom-bold-button"
            small
            :key="index"
            >{{ row }}
            <v-badge
              bordered
              color="error"
              class="custom-margin"
              overlap
              dot
              offset-x="10"
              offset-y="10"
            >
            </v-badge>
          </v-btn>
        </div>
      </v-col> -->
    <ListingTemplate
      :customClass="'warranty-listing'"
      v-if="getPermission('warranty:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 mxw-200 my-auto">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="false">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in customerMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  v-on:click="createPmVisit()"
                  color="orange"
                  class="mx-2 custom-bold-button white--text"
                >
                  <v-icon dark left>mdi-plus</v-icon> PM Visit
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_warranty
                          }}</template>
                          <template v-else>{{
                            item.preventive_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>

              <v-spacer></v-spacer>

              <v-flex
                class="pt-0 justify-flex-end d-flex margin-auto all-project-btn-right"
              >
                <div style="width: 250px">
                  <CustomerFilter
                    v-if="false"
                    :disabled="dataLoading"
                    v-on:change="getRows"
                  />
                  <v-autocomplete
                    hide-details
                    v-model.trim="filter_pm_customer"
                    clearable
                    :items="allCustomerList"
                    dense
                    flat
                    filled
                    label="Customer"
                    item-color="cyan"
                    color="cyan"
                    solo
                    :disabled="dataLoading || customerLoading"
                    :loading="customerLoading || dataLoading"
                    item-text="display_name"
                    item-value="id"
                    v-on:change="
                      getPropertyList(filter_pm_customer);
                      getRows();
                    "
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'No Customer(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar>
                        <v-img
                          :lazy-src="$defaultProfileImage"
                          :src="item.profile_logo"
                          aspect-ratio="1"
                          class="margin-auto"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.display_name }}</v-list-item-title
                        >
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.company_name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        v-if="item.default_person"
                        class="align-self-center"
                      >
                        <v-list-item-subtitle
                          class="text-lowercase pb-2 font-weight-500 font-size-14"
                          >{{
                            item.default_person.primary_email
                          }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          class="text-lowercase font-weight-500 font-size-14"
                          >{{
                            item.default_person.primary_phone
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </div>
                <div style="width: 250px" class="ml-2">
                  <v-autocomplete
                    hide-details
                    clearable
                    v-model.trim="filter_property"
                    :items="propertyList"
                    dense
                    flat
                    filled
                    label="Site Location"
                    item-color="cyan"
                    color="cyan"
                    solo
                    :disabled="dataLoading || propertyLoading"
                    :loading="propertyLoading || dataLoading"
                    item-text="barcode"
                    item-value="id"
                    v-on:change="getRows"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'No Site Location(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-icon class="mr-3">
                        <v-icon>mdi-home-map-marker</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content
                        class="text-ellipsis max-width-400px"
                      >
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.barcode }} ({{ getFormattedProperty(item) }})
                          <div>
                            <b>{{ item.property_name }}</b>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </div>
                <div style="width: 250px" class="ml-2">
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        flat
                        label="PM Date Range"
                        hide-details
                        solo
                        :loading="dataLoading"
                        :disabled="dataLoading"
                        clearable
                        prepend-inner-icon="mdi-calendar"
                        v-on:click:clear="clearFilter('dates')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formattedDate"
                        color="cyan"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="cyan"
                      range
                      :disabled="dataLoading"
                      v-on:change="getRows"
                      v-model="dates"
                    ></v-date-picker>
                  </v-menu>
                </div>

                <template>
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    v-on:click="createPreventive()"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <!-- <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  color="cyan white--text"
                  v-on:click="exportPreventiveVisit"
                >
                  <v-icon dark left>mdi-database-export</v-icon>
                </v-btn> -->
                <!--  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    color="cyan white--text"
                    v-on:click="searchDialog = true"
                  >
                    <v-icon dark>mdi-filter</v-icon>
                  </v-btn> -->
                <!--  <v-btn
                    v-if="!isEngineerChannel()"
                    :disabled="dataLoading"
                    class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                    color="cyan white--text"
                    v-on:click="
                      $router.push(
                        getDefaultRoute('profile.setting', {
                          query: {
                            tab: 'customer',
                          },
                        })
                      )
                    "
                  >
                    <v-icon dark>mdi-cog</v-icon>
                  </v-btn> -->
                <!-- <v-menu content-class="custom-menu-list" offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-on:click="exportCustomer"
                      >
                        <v-icon dark left>mdi-database-export</v-icon> Export
                      </v-btn>
                      <v-btn
                        :disabled="dataLoading || exportLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        :loading="exportLoading"
                      >
                        <v-icon dark>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in moreActions">
                        <v-list-item
                          :key="index"
                          link
                          v-on:click="moreAction(item.action, item.sort)"
                        >
                          <v-list-item-title>
                            <v-icon v-if="item.icon" left color="cyan">{{
                              item.icon
                            }}</v-icon>
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu> -->
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    v-on:change="updateVisible()"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>

                <PageTips v-if="false" module="customer"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Preventive"
          :basicSearchFields="['unique_id']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <!-- <pre>{{defaultColDefs}}</pre> -->
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <!--  <v-checkbox
                                                        dense
                                                        :disabled="dataLoading"
                                                        v-model="selectedAll"
                                                        color="cyan"
                                                        class="hide-margin"
                                                        hide-details
                                                    ></v-checkbox> -->
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>

                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="warranty-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            :disabled="data.status != 2 ? true : false"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                            @change="
                              pmCustomValidation(data, index),
                                checkBoxDialog(data)
                            "
                          ></v-checkbox>
                        </template>

                        <template v-else-if="cols.field == 'customer'">
                          <div class="warranty-listing-product">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              <template
                                v-if="
                                  data.customer && data.customer.display_name
                                "
                                >{{ data.customer.display_name }}</template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >No Display Name</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>
                              <template
                                v-if="
                                  data.customer && data.customer.company_name
                                "
                                >{{ data.customer.company_name }}</template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >No Company</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template
                                v-if="
                                  data.customer && data.customer.company_email
                                "
                                >{{ data.customer.company_email }}</template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >No Email</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="
                                  data.customer && data.customer.company_number
                                "
                                >{{ data.customer.company_number }}</template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >No Phone</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'dates'">
                          <div class="warranty-listing-product">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Start Date: </b>
                              <template v-if="data && data.start_date">
                                <v-chip
                                  small
                                  outlined
                                  color="red"
                                  label
                                  class="ml-1 mb-1"
                                >
                                  {{ formatDate(data.start_date) }}
                                  {{ formatDateStartTime(data.start_date) }}
                                </v-chip>
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Start Date</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>End Date: </b>
                              <template v-if="data && data.end_date">
                                <v-chip
                                  small
                                  outlined
                                  color="green"
                                  label
                                  class="ml-2"
                                >
                                  {{ formatDate(data.end_date) }}
                                  {{ formatDateStartTime(data.end_date) }}
                                </v-chip>
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No End Date</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'other'">
                          <div class="m-0 custom-nowrap-ellipsis d-flex">
                            <div class="font-weight-600">Title :</div>
                            <template v-if="data && data.title">
                              <div class="my-auto">{{ data.title }}</div>
                            </template>
                            <em class="text-muted" v-else> no title </em>
                          </div>
                          <div class="m-0 custom-nowrap-ellipsis d-flex">
                            <div class="font-weight-600">Cost:</div>
                            <template v-if="data && data.cost">
                              <div class="font-weight-700 my-auto">
                                {{ formatMoney(data.cost) }}
                              </div>
                            </template>
                            <template v-else
                              ><em class="text--secondary"
                                >No cost</em
                              ></template
                            >
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'count'">
                          <div class="contract-listing-customer">
                            <p class="m-1 custom-nowrap-ellipsis-count mb-2">
                              <v-badge
                                color="cyan"
                                :content="data.schedule_count"
                                class="mr-9 ml-2"
                              >
                                <!--   Item Two -->
                              </v-badge>
                              <b>Schedule Count </b>
                              <!-- <template v-if="data.total_service">{{
                                data.total_service
                              }}</template> -->
                            </p>

                            <p class="m-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                :color="data.visit_count > 0 ? 'green' : 'red'"
                                :content="data.visit_count"
                                class="mr-9 ml-2"
                              >
                              </v-badge>
                              <b>Visit Count</b>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'property'">
                          <div class="property-listing-customer">
                            <p
                              class="m-0 custom-nowrap-ellipsis-four-line word-break"
                              v-if="data.property"
                            >
                              {{ data.property.street_1 }}
                              {{ data.property.street_2 }}
                              <br />
                              {{ data.property.unit_no }}
                              {{ data.property.country }}
                              <template
                                v-if="
                                  data &&
                                  data.property &&
                                  data.property.zip_code != '000000'
                                "
                              >
                                , {{ data.property.zip_code }}
                              </template>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <Barcode
                              class=""
                              :barcode="data.unique_id"
                            ></Barcode>
                          </p>
                          
                          <p class="m-0 custom-nowrap-ellipsis">
                            <span
                              class="schedule-text"
                              v-on:click.stop.prevent="openSchedule(data.id)"
                              >Show Schedule</span
                            >
                          </p>
                        </template>
                        <!--  <template v-else-if="cols.field == 'unique_id'">
                            <v-chip
                              label
                              small
                              class="font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="cyan"
                            >
                              {{ data.unique_id }}
                            </v-chip>
                          </template> -->

                        <template v-else-if="cols.field == 'status'">
                          <div class="project-listing-status">
                            <CustomStatus
                              small
                              :status="data.status"
                              endpoint="preventive-maintanance/status"
                            ></CustomStatus>
                          </div>
                          <v-chip
                            v-if="data.is_extended && data.is_extended == 1"
                            label
                            small
                            outlined
                            class="font-weight-600 custom-grey-border text-uppercase ml-2"
                            text-color=""
                            color="orange"
                          >
                            Extended
                          </v-chip>
                        </template>

                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateSartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="8" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no preventive maintenance at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>

        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <!--    <Dialog :commonDialog="searchDialog">
            <template v-slot:title>Filter Customer</template>
            <template v-slot:body>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 90vh; position: relative"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-row>
                    <template v-for="(field, index) in searchableArray">
                      <v-col md="6" :key="index" v-if="field != 'dates'">
                        <v-text-field
                          v-model.trim="listingSearch[field]"
                          dense
                          filled
                          hide-details
                          :label="
                            field == 'customer_detail_phone[value]'
                              ? 'Company Phone'
                              : field == 'customer_detail[value]'
                              ? 'Company Email'
                              : getFilterLabel(field, 'Customer')
                          "
                          solo
                          flat
                          clearable
                          @keydown.enter="filterRows"
                          color="cyan"
                        ></v-text-field>
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </perfect-scrollbar>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="filterRows"
              >
                Filter
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="searchDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog> -->
        <ImportTemplate
          :dialog="importDialog"
          sample-url="customer/sample-download"
          api-url="customer/import"
          title="Import Customer"
          v-on:close="importDialog = false"
        ></ImportTemplate>
        <template v-if="addWarrantyDialog && getPermission('warranty:create')">
          <ManageWarranty
            :allCustomerList="allCustomerList"
            :allEquipemtList="allEquipemtList"
            :dialog-status="addWarrantyDialog"
            v-on:close:dialog="addWarrantyDialog = false"
            v-on:update:warranty="getRows"
          ></ManageWarranty>
        </template>
      </template>
    </ListingTemplate>
    <ExtentWarranty
      v-if="extentWarrantyDialog"
      :allCustomerList="allCustomerList"
      :allEquipemtList="allEquipemtList"
      :warrantyDetail="warrantyArr"
      :dialogStatus="extentWarrantyDialog"
      v-on:close:dialog="extentWarrantyDialog = false"
      v-on:update:warranty="getRows"
    ></ExtentWarranty>
    <PMSchedule
      v-if="scheduleDialog"
      :parent-id="pmId"
      :dialogStatus="scheduleDialog"
      :updateted-data="pmVisitArray"
      v-on:schedule:payload="saveSchedule"
      v-on:close:dialog="scheduleDialog = false"
    >
    </PMSchedule>
  </div>
  <!--end::Customer Listing-->
</template>
<style scoped>
.equipment-header label.v-label.theme--light {
  color: rgb(36, 50, 109);
  font-weight: 700 !important;
}
.equipment-footer label.v-label.theme--light {
  font-weight: 500 !important;
}
</style>
<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PATCH,
  DOWNLOAD,
  POST,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
/* import Dialog from "@/view/pages/partials/Dialog.vue"; */
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ExtentWarranty from "@/view/pages/preventive-maintanance/Extended-Warranty.vue";
import PMSchedule from "@/view/pages/preventive-maintanance/ManagePMSchedule.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
/* import Dialog from "@/view/pages/partials/Dialog.vue"; */
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import JwtService from "@/core/services/jwt.service";
import { saveAs } from "file-saver";
import moment from "moment";
import ManageWarranty from "@/view/pages/partials/Create-Or-Update-Preventive.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
//import CustomerFilter from "@/view/pages/partials/Filter/Customer-Filter.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";
/* import { mapGetters } from "vuex";
 */
import { /* getConfig, */ saveData } from "@/core/services/local.service";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "preventive-list",
  data() {
    return {
      pmId: 0,
      pageTips: false,
      exportLoading: false,
      pageModule: "preventive-listing",
      scheduleDialog: false,
      routeAPI: "preventive-maintanance",
      momentObject: moment,
      routeName: "preventive-maintanance",
      routeDetailName: "preventive-maintanance.detail",
      allEquipemtList: [],
      propertyList: [],
      status: "all",
      addWarrantyDialog: false,
      allCustomerList: [],
      pageLoading: false,
      file: "",
      customerLoading: false,
      propertyLoading: false,
      warrantyArr: {},
      importDialog: false,
      extentWarrantyDialog: false,
      statusList: [],
      customThead: [],
      /*  pmVisitArray :[
                {
                    pm_id: 0,
                    scheduleId: 0,
                    iteam: [],
                    showSchedule: 0
                }
            ], */
      pmVisitArray: [],
      moreActions: [
        {
          title: "Import Customer(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      customerType: [
        {
          text: "Company",
          value: "company",
          color: "blue accent-4",
        },
        {
          text: "Individual",
          value: "personal",
          color: "red accent-4",
        },
      ],
      customerMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    PageTips,
    ImportTemplate,
    PageHeaderCount,
    Barcode,
    TableActivity,
    CustomStatus,
    ManageWarranty,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    ExtentWarranty,
    PMSchedule,
    /* CustomerFilter, */
    /*   Dialog, */
  },
  methods: {
    openSchedule(id) {
      this.pmId = id;
      this.scheduleDialog = true;
    },
    pmCustomValidation(data) {
      let validationFlag = true;
      if (this.selectedRows && this.selectedRows.length > 1) {
        let selectedRowData = this.selectedRows[0];
        let checkData = this.lodash.find(this.rowData, { id: selectedRowData });
        if (
          checkData &&
          (checkData?.customer?.id != data?.customer?.id ||
            checkData?.customer?.id == data?.customer?.id) &&
          checkData?.property?.id != data?.property?.id
        ) {
          let index = this.selectedRows.indexOf(data.id);
          if (index !== -1) {
            this.selectedRows.splice(index, 1);
          }
          ErrorEventBus.$emit(
            "update:error",
            "Please ensure you select the same customer and site location for the PM."
          );
          validationFlag = false;
          return false;
        }
      }
      if (validationFlag) {
        const foundIndex = this.pmVisitArray.findIndex(
          (item) => item.pm_id === data?.id
        );
        if (foundIndex !== -1) {
          this.pmVisitArray.splice(foundIndex, 1);
        } else {
          this.pmVisitArray.push({ pm_id: data?.id });
        }
      }
    },
    checkBoxDialog(row) {
      if (
        this.selectedRows &&
        this.selectedRows.includes(row?.id) &&
        row.schedule_done_count == 0
      ) {
        this.openSchedule(row.id);
      }
    },
    saveSchedule(data) {
      this.pmVisitArray.includes(data?.pm_id);
      if (this.selectedRows.includes(data?.pm_id)) {
        const foundIndex = this.pmVisitArray.findIndex(
          (item) => item.pm_id == data?.pm_id
        );
        this.pmVisitArray.splice(foundIndex, 1, data);
        this.scheduleDialog = false;
      } else {
        ErrorEventBus.$emit(
          "update:error",
          "Please select Preventive Maintanance"
        );
      }
    },
    createPmVisit() {
      let new_array = [];
      let error = true;
      new_array = this.rowData.filter((item) =>
        this.selectedRows.includes(item.id)
      );
      /* console.log(this.pmVisitArray) */
      new_array.forEach((row) => {
        const foundIndex = this.pmVisitArray.find(
          (item) => item.pm_id == row.id
        );
        /*     if(!foundIndex?.showSchedule){
          console.log({foundIndex})
        }else{
          console.log('{foundIndex}')
        } */

        if (row.schedule_done_count == 0 && !foundIndex?.showSchedule) {
          ErrorEventBus.$emit(
            "update:error",
            `Please select schedule for ${row.barcode}`
          );
          error = false;
          return false;
        }
      });
      if (error) {
        if (this.selectedRows?.length) {
          this.$router.push(
            this.getDefaultRoute("job.create", {
              query: {
                pmSchedule: JSON.stringify(this.pmVisitArray),
                type: "multiple",
              },
            })
          );
        } else {
          ErrorEventBus.$emit(
            "update:error",
            "Please select atleast one Preventive Maintanance"
          );
          return false;
        }
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        /*    case "search":
                  _this.filter_search = null;
                  break; */
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getRows();
    },
    getPropertyList(param) {
      const _this = this;
      _this.propertyList = [];
      _this
        .getCustomerProperties(param)
        .then((status) => {
          _this.propertyList = status;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      return tempArr.join(", ");
    },
    getCustomerProperties(customer) {
      const _this = this;
      _this.propertyLoading = true;
      _this.filter_property = null;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "property-list/" + (customer || ""),
            })
            .then(({ data }) => {
              saveData("_btacpl_" + customer + "_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.propertyLoading = false;
            });
        } catch (error) {
          _this.propertyLoading = false;
          reject(error);
        }
      });
    },
    exportPreventiveVisit() {
      const { query } = this.$route;
      /*  const { search, date_range, customer , property, engineer, visit_status } = this.ltxFilter; */
      let downloadURL = process.env.VUE_APP_API_URL + "preventive/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      if (query) {
        this.lodash.map(query, (row, index) => {
          if (row) {
            downloadURL.searchParams.append(`${index}`, row);
          }
        });
      }
      if (this.dates && this.dates[0] && this.dates[1]) {
        if (this.dates && this.dates[0]) {
          downloadURL.searchParams.append("daterange[0]", this.dates[0]);
        }
        if (this.dates && this.dates[1]) {
          downloadURL.searchParams.append("daterange[1]", this.dates[1]);
        }
      }
      if (this.filter_pm_customer) {
        downloadURL.searchParams.append(
          "filter_customer",
          this.filter_pm_customer
        );
      }
      if (this.filter_property) {
        downloadURL.searchParams.append(
          "filter_property",
          this.filter_property
        );
      }
      window.open(downloadURL, "_blank");
    },
    createPreventive() {
      this.$router.push(this.getDefaultRoute("preventive.create"));
    },
    remaningDate(date) {
      var a = moment(date.end_date);
      //var b = moment(date.start_date);
      //console.log(b);
      var b = moment().format("YYYY-MM-DD");
      b = moment(b);
      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days <= 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months <= 0) {
        months = "";
      } else {
        months = months + " Months ";
      }
      if (years <= 0) {
        years = "";
      } else {
        years = years + " Years ";
      }
      return years + months + days;
    },
    totalDays(date) {
      let today = moment().format("YYYY-MM-DD");

      var a = moment(date.end_date);
      var b = moment(today); //date.start_date
      var final = a.diff(b, "days"); // =1
      if (final <= 0) {
        final = 0;
      }
      return final;
    },
    formatDateSartTime(row) {
      return moment(row).format("hh:mm A");
    },
    extentWarranty(id) {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "preventive-maintanance/" + id,
        })
        .then(({ data }) => {
          _this.warrantyArr = data;
          if (_this.warrantyArr) {
            this.extentWarrantyDialog = true;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    getAllCustomers() {
      const _this = this;
      (_this.customerLoading = true),
        _this.$store
          .dispatch(GET, { url: "customer-list" })
          .then(({ data }) => {
            _this.allCustomerList = data;
            _this.customerLoading = false;
          })
          .catch((error) => {
            _this.logError(error);
          });
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
        case "equipment":
          result = "Equipment";
          break;
      }
      return result;
    },
    routeToDetail(row) {
      if (row) {
        if (row.product_type === "goods") {
          this.$router.push(
            this.getDefaultRoute("product.detail", {
              params: {
                id: row.product_id,
              },
            })
          );
        } else if (row.product_type === "equipment") {
          this.$router.push(
            this.getDefaultRoute("equipment.detail", {
              params: {
                id: row.product_id,
              },
            })
          );
        }
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkCustomerExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("customer");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    exportCustomer() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "customer/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        // alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "customer/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkCustomerUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "customer",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkCustomerExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "customer/export",
        })
        .then(({ data }) => {
          saveAs(data, "customer-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  computed: {
    /* ...mapGetters(['ltxFilter']), */
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Preventive Maintenance",
      },
    ]);
    this.getRows();
    this.getAllCustomers();
    this.getPropertyList();
    // alert(this.defaultColShow.length)
  },

  beforeMount() {
    const _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    // _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
