<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          {{ preventiveArr?.preventive?.barcode }} :-
          <!--  <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-block mr-3"
                v-bind="attrs"
                v-on="on"
              >
                {{ preventiveArr?.preventive?.title }}
              </div>
            </template>
            <span>PM Title</span>
          </v-tooltip> -->
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-block mr-3 text-capitalize"
              >
                {{ preventiveArr?.preventive?.customer?.display_name }}
              </div>
            </template>
            <span>Display Name</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <v-layout class="mb-2">
            <v-flex md4 class="mr-2 d-flex">
              <div
                class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                style="width: 160px"
              >
                Schedule Type <span style="margin-left: 19px">:</span>
              </div>
              <template v-if="preventiveArr?.preventive?.btx_schedule_tab">
                <div class="mr-2 font-weight-600 text-capitalize">
                  <v-chip
                    small
                    outlined
                    color="blue"
                    label
                    class="text-capitalize"
                  >
                    {{ scheduleTabText }}
                  </v-chip>
                </div>
              </template>
            </v-flex>
            <v-flex md4 class="mr-2 d-flex">
              <div
                class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                style="width: 153px"
              >
                Contract Start <span style="margin-left: 18px">:</span>
              </div>
              <template v-if="preventiveArr?.preventive?.start_date">
                <div class="mr-2 font-weight-600 text-capitalize">
                  <template v-if="preventiveArr?.preventive?.start_date">
                    <v-chip small outlined color="red" label class="ml-1 mb-1">
                      {{ formatDate(preventiveArr?.preventive?.start_date) }}
                      {{
                        formatDateStartTime(
                          preventiveArr?.preventive?.start_date
                        )
                      }}
                    </v-chip>
                  </template>
                  <template v-else
                    ><em class="text--secondary">No Start Date</em></template
                  >
                </div>
              </template>
            </v-flex>
            <v-flex md4 class="mr-2 d-flex">
              <div
                class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                style="width: 153px"
              >
                Contract End <span style="margin-left: 18px">:</span>
              </div>
              <template v-if="preventiveArr?.preventive?.end_date">
                <div class="mr-2 font-weight-600 text-capitalize">
                  <template v-if="preventiveArr?.preventive?.end_date">
                    <v-chip
                      small
                      outlined
                      color="green"
                      label
                      class="ml-1 mb-1"
                    >
                      {{ formatDate(preventiveArr?.preventive?.end_date) }}
                      {{
                        formatDateStartTime(preventiveArr?.preventive?.end_date)
                      }}
                    </v-chip>
                  </template>
                  <template v-else
                    ><em class="text--secondary">No End Date</em></template
                  >
                </div>
              </template>
            </v-flex>
          </v-layout>
          <v-layout class="mb-2">
            <v-flex md12 class="mr-2 d-flex">
              <div
                class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                style="width: 160px"
              >
                PM Title <span style="margin-left: 62px">:</span>
              </div>
              <template v-if="preventiveArr?.preventive?.title">
                <div class="mr-2 font-weight-600 text-capitalize">
                  {{ preventiveArr?.preventive?.title }}
                </div>
              </template>
            </v-flex>
          </v-layout>
          <v-layout class="mb-2" v-if="false">
            <v-flex md12 class="mr-2 d-flex">
              <div
                class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                style="width: 155px"
              >
                Contract Start <span style="margin-left: 7px">:</span>
              </div>
              <template v-if="preventiveArr?.preventive?.start_date">
                <div class="mr-2 font-weight-600 text-capitalize">
                  <template v-if="preventiveArr?.preventive?.start_date">
                    <v-chip small outlined color="red" label class="ml-1 mb-1">
                      {{ formatDate(preventiveArr?.preventive?.start_date) }}
                      {{
                        formatDateStartTime(
                          preventiveArr?.preventive?.start_date
                        )
                      }}
                    </v-chip>
                  </template>
                  <template v-else
                    ><em class="text--secondary">No Start Date</em></template
                  >
                </div>
              </template>
            </v-flex>
          </v-layout>
          <v-layout class="mb-2">
            <v-flex md12 class="mr-2 d-flex" v-if="false">
              <div
                class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                style="width: 153px"
              >
                Contract End <span style="margin-left: 19px">:</span>
              </div>
              <template v-if="preventiveArr?.preventive?.end_date">
                <div class="mr-2 font-weight-600 text-capitalize">
                  <template v-if="preventiveArr?.preventive?.end_date">
                    <v-chip
                      small
                      outlined
                      color="green"
                      label
                      class="ml-1 mb-1"
                    >
                      {{ formatDate(preventiveArr?.preventive?.end_date) }}
                      {{
                        formatDateStartTime(preventiveArr?.preventive?.end_date)
                      }}
                    </v-chip>
                  </template>
                  <template v-else
                    ><em class="text--secondary">No End Date</em></template
                  >
                </div>
              </template>
            </v-flex>
          </v-layout>
          <v-layout class="mb-2">
            <v-flex md12 class="mr-2 d-flex">
              <div
                class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                style="width: 160px"
              >
                Site Location <span style="margin-left: 23px">:</span>
              </div>
              <template v-if="preventiveArr?.preventive?.property?.id">
                <div
                  class="mr-2 font-weight-600 text-capitalize"
                  v-if="preventiveArr?.preventive?.property"
                >
                  {{ preventiveArr?.preventive?.property?.street_1 }},

                  <template
                    v-if="preventiveArr?.preventive?.property?.street_2"
                  >
                    {{ preventiveArr?.preventive?.property?.street_2 }},
                  </template>
                  <template v-if="preventiveArr?.preventive?.property?.unit_no">
                    {{ preventiveArr?.preventive?.property?.unit_no }},
                  </template>

                  {{ preventiveArr?.preventive?.property?.country }}
                  <template
                    v-if="
                      preventiveArr?.preventive?.property &&
                      preventiveArr?.preventive?.property?.zip_code != '000000'
                    "
                  >
                    , {{ preventiveArr?.preventive?.property?.zip_code }}
                  </template>
                </div>
              </template>
            </v-flex>
          </v-layout>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 100vh; position: relative"
          >
            <v-form
              ref="scheduleForm"
              v-model.trim="scheduleForm"
              lazy-validation
              v-on:submit.stop.prevent="onDetailSubmit()"
            >
              <v-row>
                <v-col md="12" style="margin-top: -10px !important">
                  <v-tabs
                    active-class="custom-tab-active"
                    v-model="scheduleTab"
                    background-color="transparent"
                    color="cyan"
                    class="custom-tab-transparent tab-sticky"
                    show-arrows
                  >
                    <v-tab
                      class="font-size-16 font-weight-600 px-8"
                      href="#schedule"
                    >
                      <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                        <!--begin::Svg Icon-->

                        <!--end::Svg Icon-->
                      </span>
                      Schedule ({{ preventiveArr?.pmSchedule?.length }})
                    </v-tab>
                    <v-tab
                      class="font-size-16 font-weight-600 px-8"
                      href="#equipment"
                    >
                      <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                        <!--begin::Svg Icon-->

                        <!--end::Svg Icon-->
                      </span>
                      Equipments ({{ preventiveArr?.pmItems?.length }})
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="scheduleTab">
                    <v-tab-item value="schedule">
                      <v-simple-table class="inner-simple-table" fixed-header>
                        <template v-slot:default>
                          <thead
                            style="
                              background-color: rgb(243, 246, 249);
                              color: rgb(36, 50, 109);
                            "
                          >
                            <tr class="custom-border-bottom">
                              <td class="font-size-16 font-weight-700">
                                Schedule #
                              </td>
                              <td class="font-size-16 font-weight-700">
                                Planned Date /Time
                              </td>
                              <td class="font-size-16 font-weight-700">
                                Actual Date /Time
                              </td>
                              <td class="font-size-16 font-weight-700">
                                PM Done
                              </td>
                              <td class="font-size-16 font-weight-700">
                                No. Of Equipment Serviced
                              </td>
                              <td
                                class="font-size-16 font-weight-700"
                                width="35%"
                              >
                                Visits
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-if="
                                lodash.isEmpty(preventiveArr?.pmSchedule) ===
                                false
                              "
                            >
                              <tr
                                v-for="(
                                  data, index
                                ) in preventiveArr?.pmSchedule"
                                :key="index"
                                :class="{ 'orange_lighten-5': index % 2 == 0 }"
                              >
                                <td>
                                  <div class="d-flex">
                                    <v-checkbox
                                      class="mr-2 custom-box equipment-footer"
                                      @change="selectAllItems"
                                      v-model="selectedSchedules"
                                      v-bind:value="Number(data.id)"
                                      color="cyan white--text"
                                      v-if="isCheck"
                                    />
                                    <div class="mt-1">
                                      <Barcode
                                        :barcode="data.barcode"
                                      ></Barcode>
                                    </div>
                                  </div>
                                </td>
                                <td class="py-2">
                                  <div class="quotation-listing-amount">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>Start: </b>
                                      <span class="font-weight-500"
                                        >{{ formatDate(data.startDate) }}
                                        {{ data.startTime }}
                                      </span>
                                    </p>
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>End: </b>
                                      <span class="font-weight-500"
                                        >{{ formatDate(data.endDate) }}
                                        {{ data.endTime }}
                                      </span>
                                    </p>
                                  </div>
                                </td>
                                <td class="py-2">
                                  <div class="quotation-listing-amount">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>Start: </b>
                                      <span
                                        class="font-weight-500"
                                        v-if="data?.schedule_visit?.length > 0"
                                        >{{
                                          formatDate(
                                            getActualStartDate(
                                              data?.schedule_visit
                                            )
                                          )
                                        }}
                                        {{
                                          formatDateStartTime(
                                            getActualStartDate(
                                              data?.schedule_visit
                                            )
                                          )
                                        }}
                                      </span>
                                      <em class="text-muted" v-else
                                        >No Start Date</em
                                      >
                                      <!--  {{getActualDate(data?.schedule_visit)}} -->
                                    </p>
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>End: </b>
                                      <span
                                        class="font-weight-500"
                                        v-if="data?.schedule_visit?.length > 0"
                                        >{{
                                          formatDate(
                                            getActualEndDate(
                                              data?.schedule_visit
                                            )
                                          )
                                        }}
                                        {{
                                          formatDateStartTime(
                                            getActualEndDate(
                                              data?.schedule_visit
                                            )
                                          )
                                        }}
                                      </span>
                                      <em class="text-muted" v-else>
                                        No End Date</em
                                      >
                                    </p>
                                  </div>
                                </td>
                                <td class="py-2">
                                  <span class="font-weight-500">
                                    <v-icon
                                      v-if="data.pm_done"
                                      size="20"
                                      :color="
                                        data.visit_flag == 1 ? 'green' : 'blue'
                                      "
                                    >
                                      mdi-check-all</v-icon
                                    >
                                    <em class="text-muted" v-else> No</em>
                                  </span>
                                </td>
                                <td class="py-2">
                                  <span
                                    class="font-weight-600"
                                    style="font-size: 15px"
                                  >
                                    <span class="green--text"
                                      ><template v-if="data.items_count">
                                        {{ data.items_count }}</template
                                      >
                                      <template v-else> 0</template>
                                    </span>
                                    /
                                    <span class="red--text">{{
                                      preventiveArr?.pmItems?.length
                                    }}</span>
                                  </span>
                                </td>
                                <td class="py-2" width="35%">
                                  <span
                                    class="font-weight-700"
                                    v-if="
                                      data &&
                                      data.schedule_visit &&
                                      data?.schedule_visit?.length > 0
                                    "
                                  >
                                    <template
                                      v-for="(
                                        row, index
                                      ) in data?.schedule_visit"
                                    >
                                      <v-tooltip
                                        top
                                        content-class="custom-top-tooltip"
                                        :key="'ticket-type' + index"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-chip
                                            v-bind="attrs"
                                            v-on="on"
                                            class="mr-2 font-weight-600"
                                            :color="
                                              getVisitColor(row.visit.status)
                                            "
                                            label
                                            small
                                            outlined
                                            @click="routeToDetail(row.visit.id)"
                                          >
                                            {{ row.visit.barcode }}
                                          </v-chip>
                                        </template>
                                        <span>{{
                                          getVisitStatusText(row.visit.status)
                                        }}</span>
                                      </v-tooltip>
                                    </template>
                                  </span>
                                  <em v-else class="text-muted"> no visit </em>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td :colspan="6">
                                  <p
                                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                                  >
                                    Sorry! No Schedule(s) Found.
                                  </p>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                    <v-tab-item value="equipment">
                      <v-simple-table
                        class="inner-simple-table custom-border-top"
                        fixed-header
                      >
                        <template v-slot:default>
                          <thead
                            style="
                              background-color: rgb(243, 246, 249);
                              color: rgb(36, 50, 109);
                            "
                          >
                            <tr class="custom-border-bottom">
                              <td
                                class="font-size-16 font-weight-700"
                                width="200"
                              >
                                <div class="d-flex">
                                  <v-checkbox
                                    dense
                                    :disabled="scheduleLoading"
                                    v-model="selectedAll"
                                    color="cyan"
                                    class="hide-mirgin"
                                    hide-details
                                    v-if="isCheck"
                                  ></v-checkbox>
                                  <div class="mt-1">Equipment No</div>
                                </div>
                              </td>
                              <td
                                class="font-size-16 font-weight-700"
                                width="200"
                              >
                                Image
                              </td>
                              <td class="font-size-16 font-weight-700">Name</td>
                              <td class="font-size-16 font-weight-700">
                                Serial No
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-if="
                                lodash.isEmpty(preventiveArr?.pmItems) === false
                              "
                            >
                              <tr
                                v-for="(
                                  row, itemIndex
                                ) in preventiveArr?.pmItems"
                                :key="itemIndex"
                                :class="{
                                  'orange_lighten-5': itemIndex % 2 == 0,
                                }"
                              >
                                <td class="py-2" width="200">
                                  <div class="d-flex">
                                    <v-checkbox
                                      dense
                                      v-model="selectedAllIteam"
                                      v-bind:value="row.id"
                                      color="cyan"
                                      class="equipment-footer"
                                      hide-details
                                      v-if="isCheck"
                                    ></v-checkbox>
                                    <div class="mt-1">
                                      <Barcode
                                        route="client-equipment.detail"
                                        :barcode="row.barcode"
                                        :id="row.eq_id"
                                      ></Barcode>
                                    </div>
                                  </div>
                                </td>
                                <td class="py-2" width="200">
                                  <template v-if="row.eq_img">
                                    <v-avatar size="50">
                                      <v-img
                                        class="customer-image"
                                        width="50"
                                        height="50"
                                        contain
                                        :lazy-src="$defaultImage"
                                        :src="row.eq_img"
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </template>
                                  <template v-else>
                                    <v-img
                                      class="customer-image"
                                      width="50"
                                      height="50"
                                      contain
                                      :lazy-src="$defaultImage"
                                      :src="$defaultImage"
                                    >
                                    </v-img>
                                  </template>
                                </td>
                                <td class="py-2">
                                  <div class="font-size-14 font-weight-600">
                                    {{ row.eq_name }}
                                  </div>
                                </td>
                                <td class="py-2">
                                  <v-chip
                                    small
                                    label
                                    color="blue"
                                    class="text-white"
                                    >{{ row.eq_serial }}</v-chip
                                  >
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td :colspan="6">
                                  <p
                                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                                  >
                                    Sorry! No Items Found.
                                  </p>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-form>
          </perfect-scrollbar>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              v-if="isCheck"
              depressed
              :disabled="!scheduleForm || scheduleLoading"
              :loading="scheduleLoading"
              class="mx-2 custom-bold-button white--text"
              v-on:click="onDetailSubmit()"
              color="cyan"
              >Save
            </v-btn>
            <v-btn
              depressed
              :disabled="scheduleLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="$emit('close:dialog')"
              >Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
import { mapGetters } from "vuex";
import { GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { ErrorEventBus } from "@/core/lib/message.lib";
import Barcode from "@/view/pages/partials/Barcode.vue";
import moment from "moment";

export default {
  name: "create-or-update-warranty",
  mixins: [CommonMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: 0,
    },
    updatetedData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    /*   DatePicker,
    Barcode, */
    Barcode,
  },
  watch: {
    dialogStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.openSchedule(this.parentId);
          this.checkData();
        }
      },
    },
    /* selectedSchedules: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param)
        if(param){
            this.selectedAll = true;
        }else{
            this.selectedAll = false;
        }
      },
    }, */
    updatetedData: {
      deep: true,
      immediate: true,
      handler(param) {
        const foundIndex = param.find((item) => item.pm_id == this.parentId);
        if (foundIndex) {
          this.selectedSchedules = foundIndex.scheduleId;
          this.selectedAllIteam = foundIndex?.iteam || [];
        }
      },
    },
  },
  data() {
    return {
      scheduleTab: "schedule",
      formValid: true,
      pageLoading: false,
      selectedAllIteam: [],
      selectedSchedules: false,
      scheduleLoading: false,
      preventiveArr: {},
      isCheck: false,
      scheduleForm: true,
    };
  },
  methods: {
    routeToDetail(id) {
      this.$router.push(
        this.getDefaultRoute("visit.detail", {
          params: { id: id },
        })
      );
    },
    getActualStartDate(row) {
      let LastStartDate = row[row.length - 1];
      return LastStartDate?.visit?.started_at;
    },
    getActualEndDate(row) {
      let LastStartDate = row[row.length - 1];
      return LastStartDate?.visit?.finished_at;
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    getVisitColor(data) {
      if (data) {
        if (data == 1) {
          return "blue";
        } else if (data == 2) {
          return "yellow";
        } else if (data == 3) {
          return "orange";
        } else if (data == 4) {
          return "green";
        } else if (data == 5) {
          return "red";
        } else if (data == 6) {
          return "orange";
        }
      }
    },
    getVisitStatusText(data) {
      if (data) {
        if (data == 1) {
          return "Open";
        } else if (data == 2) {
          return "In-Progress";
        } else if (data == 3) {
          return "Hold & Stay Assign";
        } else if (data == 4) {
          return "Completed";
        } else if (data == 5) {
          return "Cancelled";
        } else if (data == 6) {
          return "Hold & Unassigned";
        }
      }
    },
    checkData() {
      if (this.updatetedData?.length > 0) {
        this.updatetedData.forEach((row) => {
          if (row.pm_id == this.parentId) {
            this.isCheck = true;
          } /* else{
            this.isCheck = false;
           } */
        });
      }
    },
    selectAllItems(value) {
      if (value) {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }
    },
    openSchedule(id) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "preventive-schedule/" + id,
        })
        .then(({ data }) => {
          _this.preventiveArr = data;
          if (
            !this.selectedSchedules &&
            this.preventiveArr?.pmSchedule?.length > 0 &&
            this.isCheck
          ) {
            const foundIndex = this.preventiveArr?.pmSchedule.find(
              (item) => item.pm_done == 0
            );
            if (foundIndex) {
              this.selectedSchedules = foundIndex.id;
              this.selectedAll = true;
            }
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    onDetailSubmit() {
      if (!this.selectedSchedules) {
        ErrorEventBus.$emit("update:error", "Please select PM schedule");
        return false;
      }
      if (this.selectedAllIteam?.length == 0) {
        ErrorEventBus.$emit(
          "update:error",
          "Please select atleast one equipment"
        );
        return false;
      }
      const request = {
        pm_id: this.parentId,
        scheduleId: this.selectedSchedules,
        iteam: this.selectedAllIteam,
        showSchedule: 1,
      };
      this.$emit("schedule:payload", request);
    },
  },
  computed: {
    ...mapGetters(['recurringPScheduleTypeList']),
    scheduleTabText() {
      return this.recurringPScheduleTypeList?.find((row) => row.value == this.preventiveArr?.preventive?.btx_schedule_tab)?.text;
    },
    selectedAll: {
      set(param) {
        this.selectedAllIteam = [];
        if (param && this.preventiveArr?.pmItems?.length) {
          for (let i = this.preventiveArr?.pmItems?.length - 1; i >= 0; i--) {
            this.selectedAllIteam.push(this.preventiveArr?.pmItems[i].id);
          }
        }
      },
      get() {
        let totalItems = this.preventiveArr?.pmItems?.length;
        return totalItems > 0
          ? this.selectedAllIteam?.length === totalItems
          : true;
      },
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
  /* mounted(){
    this.selectedAll = true;
  } */
};
</script>
